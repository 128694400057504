<template>
  <div>
    <en-table-layout :tableData="tableData.data" :loading="loading" :tips="true">
      <div slot="tips" class="TitleBox">
        <el-tabs
          v-model="params.cat_type"
          type="card"
          class="elTabBox"
          @tab-click="POST_shopCategoryGoodsPage"
        >
          <el-tab-pane label="精选活动列表" name="1"></el-tab-pane>
          <el-tab-pane label="新品列表" name="2"></el-tab-pane>
        </el-tabs>
      </div>
      <!-- 表格上部添加和搜索按钮  -->
      <div
        slot="toolbar"
        style="display:flex;align-items:center;justify-content:space-between;width:100%;"
      >
        <div>
          <el-button
            type="primary"
            size="mini"
            v-if="params.cat_type==='1'"
            @click="GET_activeTypeList();POST_shopCategoryGoodsHave();dialogGoodsShow=true;"
          >添加</el-button>
        </div>
        <div style="display:flex;align-items:center;">
          <div class="conditions">
            <el-input
              v-model="params.goods_name"
              placeholder="请输入名称"
              class="ipt-default"
              size="mini"
            ></el-input>
          </div>
          <div class="conditions" style="margin-left:10px;">
            <el-button
              @click="POST_shopCategoryGoodsPage"
              type="primary"
              size="mini"
              class="btn-default"
            >搜索</el-button>
          </div>
        </div>
      </div>

      <template slot="table-columns">
        <!--  排序 -->
        <el-table-column label="排序" prop="sort"></el-table-column>
        <!-- 通知标题 -->
        <el-table-column label="活动名称" prop="goods_name"></el-table-column>
        <!-- 活动编号 -->
        <el-table-column label="活动编号" prop="goods_sn"></el-table-column>
        <!-- 活动分类 -->
        <el-table-column label="活动分类" prop="type">
          <template slot-scope="scope">
            <div v-if="scope.row.cat_second_name">{{ scope.row.cat_first_name + "-" + scope.row.cat_second_name }}</div>
            <div v-else>{{ scope.row.cat_first_name }}</div>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button @click="openDialog('up',scope.row)" type="primary" size="mini">上移</el-button>
            <el-button @click="openDialog('down',scope.row)" type="primary" size="mini">下移</el-button>
            <el-button @click="openDialog('del',scope.row)" type="danger" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        v-if="tableData.data"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>

    <el-dialog :title="dialogTitle" :visible.sync="centerDialogVisible" width="30%" center>
      <div class="delBox" v-if="dialogTitle==='删除确认'">删除后无法恢复,确定删除吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false" v-if="dialogTitle==='删除确认'">取 消</el-button>
        <el-button type="primary" @click="ok">确 定</el-button>
      </span>
    </el-dialog>

    <en-goods-picker
      :show="dialogGoodsShow"
      type="seller"
      :activityTypeList="activityTypeList"
      :selectedLists="selectedLists"
      api="/seller/shops/shopCategoryGoods/search_category"
      multipleApi="/seller/shops/shopCategoryGoods/serach_sn"
      @close="dialogGoodsShow = false;"
      @confirm="handleGoodsPickerConfirm"
    />
  </div>
</template>

<script>
import * as API_shopActivityList from "@/api/shopActivityList";
import * as API_activityType from "@/api/activityType";

export default {
  name: "activityList",
  data() {
    return {
      //  列表loading状态
      loading: false,

      //  是否编辑
      isEdit: false,

      // 弹框显示内容
      centerDialogVisible: false,
      dialogTitle: "",

      // 活动选择器内容
      dialogGoodsShow: false,

      // 列表参数
      params: {
        page_no: 1,
        page_size: 10,
        cat_type: "1",
        goods_name: "",
      },

      //  列表数据
      tableData: {},

      //  选中列表行
      chooseRows: "",

      /**  活动分类列表 */
      activityTypeList: [],

      /**  已经选中列表 */
      selectedLists: []
    };
  },
  mounted() {
    // 查询导师列表
    this.POST_shopCategoryGoodsPage();
  },
  activated() {
    // 查询导师列表
    this.POST_shopCategoryGoodsPage();
  },
  methods: {
    /**  查询导师列表 */
    POST_shopCategoryGoodsPage() {
      API_shopActivityList.shopCategoryGoodsPage(this.params).then((res) => {
        this.tableData = res;
        // console.log(res);
      });
    },

    /**  修改商品排序 */
    POST_shopCategoryGoodsUpdateSort(params) {
      API_shopActivityList.shopCategoryGoodsUpdateSort(params).then((res) => {
        this.POST_shopCategoryGoodsPage();
        // console.log(res);
      });
    },

    /**  删除商品 */
    DELETE_shopCategoryGoodsDel() {
      API_shopActivityList.shopCategoryGoodsDel(this.chooseRows.id, {
        sort: this.chooseRows.sort,
      }).then((res) => {
        this.chooseRows = "";
        this.POST_shopCategoryGoodsPage();
        // console.log(res);
      });
    },

    /**  添加精选专区商品 */
    POST_shopCategoryGoodsAdd(idStr) {
      API_shopActivityList.shopCategoryGoodsAdd(idStr).then((res) => {
        this.POST_shopCategoryGoodsPage();
        // console.log(res);
      });
    },

    /**  查询已有商品 */
    POST_shopCategoryGoodsHave() {
      API_shopActivityList.shopCategoryGoodsHave({type: 1}).then((res) => {
        // this.selectedListsselectedLists = res;
        this.selectedLists = [];
        res.forEach(item => {
          item.goods_image = item.small;
          item.goods_price = item.price;
          this.selectedLists.push(item)
        })
        // console.log(res);
      });
    },

    /**  获取活动分类列表 */
    GET_activeTypeList() {
      API_activityType.activeTypeList().then((res) => {
        console.log(res);
        var _res = [];
        for (var name in res) {
          _res.push(res[name]);
        }
        this.activityTypeList = [];
        _res.forEach((item1, index1) => {
          var jsonParent = {};
          jsonParent.value = item1.category_id;
          jsonParent.label = item1.name;
          var children = [];
          if (item1.children !== null) {
            item1.children.forEach((item2, index2) => {
              var jsonChild = {};
              jsonChild.value = item2.category_id;
              jsonChild.label = item2.name;
              children.push(jsonChild);
            });
          }
          jsonParent.children = children;
          this.activityTypeList.push(jsonParent);
        });
      });
    },

    /**  活动选择器选中活动 */
    handleGoodsPickerConfirm(list) {
      var idStr = "";
      /**  查询商家专区已有商品 */
      API_shopActivityList.shopCategoryGoodsHave({type: 1}).then((res) => {
        console.log("----------------------------------")
        console.log(res);
        list.forEach((item, index) => {
          if (idStr === "") {
            idStr += item.goods_id;
          } else {
            idStr += "," + item.goods_id;
          }
        });
        this.POST_shopCategoryGoodsAdd(idStr);
      });
    },

    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.POST_shopCategoryGoodsPage();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.POST_shopCategoryGoodsPage();
    },

    openDialog(type, row) {
      var json = {};
      json.sort = row.sort;
      if (type === "up") {
        json.type = -1;
        this.POST_shopCategoryGoodsUpdateSort(json);
        return false;
      } else if (type === "down") {
        json.type = 1;
        this.POST_shopCategoryGoodsUpdateSort(json);
        return false;
      } else if (type === "del") {
        this.dialogTitle = "删除确认";
        this.chooseRows = row;
      }
      this.centerDialogVisible = true;
    },

    // 弹窗确定按钮
    ok() {
      if (this.dialogTitle === "删除确认") {
        this.DELETE_shopCategoryGoodsDel();
      }
      this.centerDialogVisible = false;
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
/deep/ .el-table td:not(.is-left) {
  text-align: center;
}

/deep/ .tips-info {
  padding: 0 !important;
  margin: 0;
  border-radius: 12px 12px 0 0;
}

/deep/ .toolbar {
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 10px;
}

.conditions {
  display: flex;
  align-items: center;

  div {
    font-size: 15px;
    font-weight: 500;
    color: rgba(68, 68, 68, 1);
  }
}

.dialogContent {
  width: 100%;

  h4 {
    margin-top: 0;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
  }

  div {
    color: #333;
    font-size: 13px;
    line-height: 20px;
  }
}
</style>
