/**
 * 店铺专区商品相关API
 */

import request from '@/utils/request'

/**
 * 查询店铺专区列表
 * @param params
 */
export function shopCategoryGoodsPage(params) {
  return request({
    url: '/seller/shops/shopCategoryGoods/page',
    method: 'post',
    loading: false,
    params
  })
}

/**
 * 修改商品排序
 * @param params
 */
export function shopCategoryGoodsUpdateSort(params) {
  return request({
    url: '/seller/shops/shopCategoryGoods/update_sort',
    method: 'post',
    loading: false,
    params
  })
}

/**
 * 删除商品
 * @param id params
 */
export function shopCategoryGoodsDel(id, params) {
  return request({
    url: `/seller/shops/shopCategoryGoods/${id}`,
    method: 'delete',
    loading: false,
    params
  })
}

/**
 * 添加精选专区商品
 * @param goods_ids
 */
export function shopCategoryGoodsAdd(goods_ids) {
  return request({
    url: `/seller/shops/shopCategoryGoods/add/${goods_ids}`,
    method: 'post',
    loading: false
  })
}

/**
 * 查询商家专区已有商品查询商家专区已有商品
 * @param params
 */
export function shopCategoryGoodsHave(params) {
  return request({
    url: `/seller/shops/shopCategoryGoods/have`,
    method: 'get',
    loading: false,
    params
  })
}
